import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ICasinoGamesConditions } from '@modules/common/casinoGames/types';

interface IInitialState {
  casinoGamesFormState: ICasinoGamesConditions;
  casinoGamesConfig: {
    isSelectorChanged: boolean;
    isCasinoGamesError: boolean;
  };
}

const initialState: IInitialState = {
  casinoGamesConfig: {
    isSelectorChanged: false,
    isCasinoGamesError: false,
  },
  casinoGamesFormState: {
    includedGamesConditions: [],
    excludedGamesConditions: [],
  },
};

const casinoGamesSlice = createSlice({
  initialState,
  name: 'casinoGamesSlice',
  reducers: {
    setExcludedGamesConditions(
      state: IInitialState,
      action: PayloadAction<ICasinoGamesConditions['excludedGamesConditions']>
    ) {
      state.casinoGamesFormState.excludedGamesConditions = action.payload;
    },
    setIncludedGamesConditions(
      state: IInitialState,
      action: PayloadAction<ICasinoGamesConditions['includedGamesConditions']>
    ) {
      state.casinoGamesFormState.includedGamesConditions = action.payload;
    },
    setCasinoGamesConfig(
      state: IInitialState,
      action: PayloadAction<Partial<IInitialState['casinoGamesConfig']>>
    ) {
      state.casinoGamesConfig = {
        ...state.casinoGamesConfig,
        ...action.payload,
      };
    },
  },
});

export default casinoGamesSlice.reducer;

export const {
  setCasinoGamesConfig,
  setIncludedGamesConditions,
  setExcludedGamesConditions,
} = casinoGamesSlice.actions;

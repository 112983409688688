import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ISearchParams, OPERATOR_SORT } from '@features/filters/types';

interface IInitialState {
  decisionJson: string;
  searchParams: ISearchParams;
}

export const initialCampaignMonitorSearchParams = {
  page: 1,
  size: 10,
  filters: [],
  sorts: [
    {
      order: OPERATOR_SORT.DESC,
      field: 'trigger_received_at',
    },
  ],
};

const initialState: IInitialState = {
  decisionJson: '',
  searchParams: initialCampaignMonitorSearchParams,
};

const campaignMonitorSlice = createSlice({
  initialState,
  name: 'campaignMonitorSlice',
  reducers: {
    setDecisionJon(state: IInitialState, action: PayloadAction<string>) {
      state.decisionJson = action.payload;
    },
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
  },
});

export default campaignMonitorSlice.reducer;

export const { setDecisionJon, setSearchParams } = campaignMonitorSlice.actions;

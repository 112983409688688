import { type PaletteColorOptions } from '@mui/material/styles';

import { type ThemeMode } from '@features/appConfig/types';
import { type PalettesProps } from '@themes/theme/presetPalettes';
import { type IPaletteThemeProps } from '@themes/types/theme';

// Description: New colors. Will be removed after all color schemes big refactor

// Main orange - primaryColors[5] (#f6901d)
// Orbit grey bg - primaryColors[0] (#eff2f4)
// Text and inputs border/placeholder - primaryColors[8] (#1c4d5c)
// Main green, buttons background - successColors[2] (#00bc57)
// DateTime Pickers green - successColors[1] (#3cd4a0)
// Error - errorColors[5] (#f44336)
// Blue text - infoColors[2] (#536dfe)
// List item hover - infoColors[4] (#afcad7)

const PlatformTheme = (
  colors: PalettesProps,
  mode: ThemeMode
): IPaletteThemeProps => {
  const { grey } = colors;

  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16],
    A900: grey[17],
  };
  let contrastBackground = '#ffffff';

  let primaryColors = [
    '#eff2f4',
    '#fee9b9',
    '#fdda8a',
    '#fccb5b',
    '#fcbf37',
    '#f6901d',
    '#f6901d',
    '#faa40e',
    '#1c4d5c',
    '#f88c06',
  ];
  let errorColors = [
    '#fde6e5',
    '#f9c1bd',
    '#f69891',
    '#f26f65',
    '#ef5044',
    '#ff5c93',
    '#f44336',
    '#e7251a',
    '#e41f15',
    '#df130c',
  ];
  let warningColors = ['#FFF7E0', '#FFC926', '#FFBF00', '#FFB900', '#FFA900'];
  let infoColors = [
    '#e7ecf1',
    '#26B0BA',
    '#536dfe',
    '#009AA7',
    '#afcad7',
    '#141414',
  ];
  let successColors = ['#E0F5EA', '#3cd4a0', '#00bc57', '#00A04D', '#008D3A'];

  if (mode === 'dark') {
    primaryColors = [
      '#141414',
      '#f2d1b9',
      '#e9b28a',
      '#e0935b',
      '#d97b38',
      '#d26415',
      '#cd5c12',
      '#c7520f',
      '#fff',
      '#b63606',
    ];
    errorColors = [
      '#fde6e5',
      '#f9c1bd',
      '#f69891',
      '#f26f65',
      '#ef5044',
      '#ff5c93',
      '#f44336',
      '#e7251a',
      '#e41f15',
      '#df130c',
    ];
    warningColors = ['#342c1a', '#836611', '#dda705', '#e9bf28', '#f8e577'];
    infoColors = [
      '#141414',
      '#11595f',
      '#536dfe',
      '#1ea6aa',
      '#afcad7',
      '#ffffff',
    ];
    successColors = ['#1a2721', '#3cd4a0', '#00bc57', '#1da65d', '#61ca8b'];

    contrastBackground = '#141414';
  }

  return {
    grey: greyColors,
    success: {
      dark: successColors[3],
      main: successColors[2],
      light: successColors[1],
      darker: successColors[4],
      lighter: successColors[0],
    },
    info: {
      A700: infoColors[5],
      dark: infoColors[3],
      main: infoColors[2],
      light: infoColors[1],
      darker: infoColors[4],
      lighter: infoColors[0],
    },
    warning: {
      dark: warningColors[3],
      main: warningColors[2],
      light: warningColors[1],
      darker: warningColors[4],
      lighter: warningColors[0],
      contrastText: greyColors[100],
    },
    error: {
      900: errorColors[9],
      700: errorColors[7],
      400: errorColors[4],
      200: errorColors[2],
      100: errorColors[1],
      dark: errorColors[6],
      main: errorColors[5],
      light: errorColors[3],
      darker: errorColors[8],
      lighter: errorColors[0],
    },
    primary: {
      contrastBackground,
      900: primaryColors[9],
      700: primaryColors[7],
      400: primaryColors[4],
      200: primaryColors[2],
      100: primaryColors[1],
      dark: primaryColors[6],
      main: primaryColors[5],
      light: primaryColors[3],
      darker: primaryColors[8],
      lighter: primaryColors[0],
    },
    secondary: {
      A100: greyColors[0],
      800: greyColors[800],
      600: greyColors[600],
      400: greyColors[400],
      200: greyColors[200],
      100: greyColors[100],
      A300: greyColors.A700,
      A200: greyColors.A400,
      dark: greyColors[700],
      light: greyColors[300],
      darker: greyColors[900],
      lighter: greyColors[100],
      contrastText: greyColors[0],
      main: greyColors[500] ?? grey[6],
    },
  };
};

export default PlatformTheme;

import { OPERATOR_TYPE } from '@features/filters/types';
import { type ITargetGroup } from '@modules/automaticCampaigns/types';

export const automaticCampaignsTextLabel = {
  ratesTooltip: 'View Rates',
  uploadControl: '.csv file max to 2 MB',
  uploadControlTitle: 'Click or Drag to upload file',
  accumulatedAmountTitle: 'Accumulated Trigger Conditions',
  excludedPlayers: 'Amount of Players excluded from the campaign:',
  applicablePlayers: 'Amount of Players applicable for the campaign:',
  unsavedChanges: 'There are unsaved changes, would you like to continue?',
  ratesDisabledTooltip: 'The Min Accumulated Amount and Brands must be filled',
  campaignRelationPopupInactiveItemTooltip:
    'Inactive campaign may disrupt the configured chain',
  uploadFileHint:
    'The file will be validated after completion of the creation/modification process.',
  activatingStatus:
    'Activating will make the campaign fully functional for the targeted players. Continue?',
  deactivatingStatus:
    'Deactivating will make the campaign not functional for the targeted players. Continue?',
  changeBrandPopup:
    'The change will cause the reset of previously selected bonuses, sport conditions, and the campaign relation configurations. Continue?',
  enableDailyTimeRangesTooltip:
    'An option that allows to long-running campaigns to trigger offers only within the specified time slot of the day, such as happy hour campaigns.',
  campaignRelationTooltip:
    'With Campaign Relation configured, players become eligible for the campaign only if the related campaign has been activated within the specified period.',
  overrideBonusTimezoneTooltip:
    'When selected, upon granting the bonus from a campaign the system will use the timezone setting from the campaign and will overwrite the timezone defined on each of the selected bonuses within the campaign.',
  uploadFileTooltip:
    'Supported file format is CSV containing Player IDs only. Remember that each individual ID must be placed on a new and separate row. The uploaded file will be validated after completion of the campaign creation/modification process.',
  uploadTypeTooltipOptions: [
    `Adds the new data to an existing source file/table (if any). Any fields that don't exist in the current table design will be added.`,
    `Overwrites the data of the existing source file/table and load the new data into it.`,
  ],
  accumulatedAmountSwitchTooltip:
    'When enabled, the campaign will trigger when the player reaches the specified amount through one or more transactions. Once the campaign is triggered, the accumulated amount resets, and a new round of accumulation will start.',
  excludeDuplicatedBetsTooltip:
    'When selected, this option ensures that duplicated bets are excluded from the calculation of the Accumulated Amount. Only the first bet placed on a specific selection will qualify for the Accumulated Amount, while any additional bets on the same selection will be disregarded.',
};

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  trigger: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  betActionType: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  bonuses: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
};

export const targetGroup: ITargetGroup = {
  SEGMENT: 'Segment',
  OPEN_FOR_ALL: 'Open for all',
};

import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import { type Option } from '@shared/form/types';

type ResponseKey = 'code' | 'name' | 'title' | 'label' | 'displayName';

interface IResponseToSelectOptions {
  withId?: boolean;
  key: ResponseKey;
}
export function responseToSelectOptions<T>(
  response: T,
  options?: IResponseToSelectOptions
): Option[] {
  if (isEmpty(response)) {
    return [];
  }

  if (Array.isArray(response)) {
    return response.map((item) => {
      const label = options?.key
        ? `${item[options.key]} ${options.withId ? `(${item.id})` : ''}`.trim()
        : item;

      return {
        label,
        value: item.id ?? item,
        ...(item.activationAction && {
          activationAction: item.activationAction,
        }),
      };
    });
  }

  if (isObject(response)) {
    return Object.entries(response).map(([key]) => ({
      label: key,
      value: key,
    }));
  }

  return [];
}

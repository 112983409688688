import { alpha, type Theme } from '@mui/material/styles';

import { type ICustomShadowProps } from '@themes/types/theme';

const CustomShadows = (theme: Theme): ICustomShadowProps => ({
  text: `0 -1px 0 rgb(0 0 0 / 12%)`,
  grey: `0 0 0 2px ${alpha(theme.palette.grey[500], 0.2)}`,
  info: `0 0 0 2px ${alpha(theme.palette.info.main, 0.2)}`,
  error: `0 0 0 2px ${alpha(theme.palette.error.main, 0.2)}`,
  success: `0 0 0 2px ${alpha(theme.palette.success.main, 0.2)}`,
  warning: `0 0 0 2px ${alpha(theme.palette.warning.main, 0.2)}`,
  primary: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
  greyButton: `0 14px 12px ${alpha(theme.palette.grey[500], 0.2)}`,
  infoButton: `0 14px 12px ${alpha(theme.palette.info.main, 0.2)}`,
  errorButton: `0 14px 12px ${alpha(theme.palette.error.main, 0.2)}`,
  secondary: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.2)}`,
  tableRow: `0 0 10px 7px ${alpha(theme.palette.primary.darker, 0.25)}`,
  iconButton: `0 20px 20px ${alpha(theme.palette.primary.darker, 0.2)}`,
  successButton: `0 14px 12px ${alpha(theme.palette.success.main, 0.2)}`,
  warningButton: `0 14px 12px ${alpha(theme.palette.warning.main, 0.2)}`,
  primaryButton: `0 14px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
  tableBody: `inset 0 0 14px ${alpha(theme.palette.primary.darker, 0.4)}`,
  secondaryButton: `0 14px 12px ${alpha(theme.palette.secondary.main, 0.2)}`,
  button:
    theme.palette.mode === 'dark'
      ? `0 2px 0 rgb(0 0 0 / 5%)`
      : `0 2px #0000000b`,
  z1:
    theme.palette.mode === 'dark'
      ? `0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)`
      : `0px 1px 4px ${alpha(theme.palette.grey[900], 0.08)}`,
});

export default CustomShadows;

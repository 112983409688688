import { DATE_TIME_FULL_FORMAT } from '@constants/date';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export const formattedDate = (
  date: Date | null | number,
  format: string = DATE_TIME_FULL_FORMAT
): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }

  return dayjs(date).tz('GMT').format(format);
};

export const millisecondsToDays = (duration: number) =>
  dayjs.duration(duration, 'milliseconds').asDays();

export const daysToMilliseconds = (days: number) =>
  dayjs.duration(Number(days), 'day').asMilliseconds();

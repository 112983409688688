import { CssBaseline, StyledEngineProvider } from '@mui/material';
import {
  createTheme,
  type Theme,
  ThemeProvider,
  type TypographyVariantsOptions,
} from '@mui/material/styles';
import { type FC, type ReactNode, useMemo } from 'react';

import { useAppSelector } from '@hooks';
import CustomShadows from '@themes/customShadows';
import componentsOverride from '@themes/overrides';
import Palette from '@themes/palette';
import { type ICustomShadowProps } from '@themes/types/theme';
import Typography from '@themes/typography';

interface IThemeCustomizationProps {
  children?: ReactNode;
}

const ThemeCustomization: FC<IThemeCustomizationProps> = ({ children }) => {
  const appConfig = useAppSelector((state) => state.appConfig.config);

  const theme: Theme = useMemo<Theme>(
    () => Palette(appConfig.mode, appConfig.presetColor),
    [appConfig.mode, appConfig.presetColor]
  );

  const themeTypography: TypographyVariantsOptions =
    useMemo<TypographyVariantsOptions>(
      () => Typography(appConfig.fontFamily),
      [appConfig.fontFamily]
    );

  const themeCustomShadows: ICustomShadowProps = useMemo<ICustomShadowProps>(
    () => CustomShadows(theme),
    [theme]
  );

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
      customShadows: themeCustomShadows,
      direction: appConfig.themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
    }),
    [appConfig.themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeCustomization;

import { Box, Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';
import {
  type CSSProperties,
  type ExoticComponent,
  forwardRef,
  type ReactElement,
  type Ref,
} from 'react';

interface IMenuTransition {
  in?: boolean;
  type?: string;
  position?: string;
  sx?: CSSProperties;
  children?: ReactElement;
  [others: string]: unknown;
  direction?: 'up' | 'down' | 'left' | 'right';
}

export const MenuTransition = forwardRef(
  (
    {
      sx,
      children,
      type = 'grow',
      direction = 'up',
      position = 'top-left',
      ...others
    }: IMenuTransition,
    ref: Ref<ExoticComponent>
  ) => {
    let positionSX;

    switch (position) {
      case 'top-right':
        positionSX = {
          transformOrigin: 'top right',
        };
        break;
      case 'top':
        positionSX = {
          transformOrigin: 'top',
        };
        break;
      case 'bottom-left':
        positionSX = {
          transformOrigin: 'bottom left',
        };
        break;
      case 'bottom-right':
        positionSX = {
          transformOrigin: 'bottom right',
        };
        break;
      case 'bottom':
        positionSX = {
          transformOrigin: 'bottom',
        };
        break;
      case 'top-left':
      default:
        positionSX = {
          transformOrigin: '0 0 0',
        };
        break;
    }

    return (
      <Box ref={ref}>
        {type === 'grow' && (
          <Grow
            {...others}
            timeout={{
              exit: 150,
              appear: 0,
              enter: 150,
            }}
          >
            <Box sx={positionSX}>{children}</Box>
          </Grow>
        )}

        {type === 'collapse' && (
          <Collapse {...others} sx={positionSX}>
            {children}
          </Collapse>
        )}

        {type === 'fade' && (
          <Fade
            {...others}
            timeout={{
              exit: 150,
              appear: 0,
              enter: 300,
            }}
          >
            <Box sx={positionSX}>{children}</Box>
          </Fade>
        )}

        {type === 'slide' && (
          <Slide
            {...others}
            timeout={{
              exit: 150,
              appear: 0,
              enter: 150,
            }}
            direction={direction}
          >
            <Box sx={positionSX}>{children}</Box>
          </Slide>
        )}

        {type === 'zoom' && (
          <Zoom {...others}>
            <Box sx={positionSX}>{children}</Box>
          </Zoom>
        )}
      </Box>
    );
  }
);

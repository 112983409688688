import { type FieldValues, type UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ROUTES } from '@constants';
import { type ICommonError, POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

type SetErrorForm = UseFormSetError<FieldValues>;

const { ACTIVATE_CODE, GIVE_BONUS_MANAGEMENT } = ROUTES;

export const catchCommonError = (
  e: unknown,
  setErrorForm?: SetErrorForm
): void => {
  const isIframe = window.self !== window.top;

  if (
    isIframe &&
    window.location.pathname.includes(GIVE_BONUS_MANAGEMENT.ROOT)
  ) {
    sendPostMessage(POST_MESSAGE_EVENT_TYPE.ERROR_GIVE_BONUS_MODAL, e);
  }

  if (isIframe && window.location.pathname.includes(ACTIVATE_CODE.ROOT)) {
    sendPostMessage(POST_MESSAGE_EVENT_TYPE.ERROR_ACTIVATE_CODE_MODAL, e);
  }

  const { data } = (e as ICommonError) ?? {};

  data?.errors?.forEach((error) => {
    setErrorForm?.(
      error?.source?.fieldPointer,
      { message: error?.title },
      { shouldFocus: true }
    );

    toast.error(
      `Unable to process your request.\n${error?.title ?? 'Please try again'}`,
      { style: { whiteSpace: 'pre-wrap' } }
    );
  });

  if (!data?.errors?.length && data?.traceId) {
    toast.error(
      `${data?.status ?? ''}: ${data?.title ?? ''}\n${
        data?.detail ?? `Unable to process your request.\nPlease try again`
      }`,
      { style: { whiteSpace: 'pre-wrap' } }
    );
  }
};

import { Box, type CheckboxProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';

import { type IExtendedStyleProps } from '@themes/types/extended';
import { getColors } from '@themes/utils/getColors';

function getColorStyle({ color, theme }: IExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark, main, lighter } = colors;

  return {
    '& .dot': {
      backgroundColor: main,
    },
    '&:hover': {
      backgroundColor: lighter,
    },
    '&.Mui-focusVisible': {
      outlineOffset: -4,
      outline: `2px solid ${dark}`,
    },
  };
}

interface IRadioSizeProps {
  size: number;
  dotSize: number;
}

function getSizeStyle(size?: CheckboxProps['size']): IRadioSizeProps {
  switch (size) {
    case 'small':
      return { size: 16, dotSize: 8 };
    case 'large':
      return { size: 24, dotSize: 12 };
    case 'medium':
    default:
      return { size: 20, dotSize: 10 };
  }
}

function radioStyle(size?: CheckboxProps['size']) {
  const sizes: IRadioSizeProps = getSizeStyle(size);

  return {
    '& .icon': {
      width: sizes.size,
      height: sizes.size,
      position: 'relative',
      '& .dot': {
        top: '50%',
        left: '50%',
        width: sizes.dotSize,
        position: 'absolute',
        height: sizes.dotSize,
        transform: 'translate(-50%, -50%)',
      },
    },
  };
}
export default function Radio(theme: Theme) {
  const { palette } = theme;

  return {
    MuiRadio: {
      styleOverrides: {
        colorInfo: getColorStyle({ theme, color: 'info' }),
        colorError: getColorStyle({ theme, color: 'error' }),
        colorWarning: getColorStyle({ theme, color: 'warning' }),
        colorSuccess: getColorStyle({ theme, color: 'success' }),
        colorPrimary: getColorStyle({ theme, color: 'primary' }),
        colorSecondary: getColorStyle({ theme, color: 'secondary' }),
        root: {
          color: palette.secondary[300],
          '&.size-large': {
            ...radioStyle('large'),
          },
          '&.size-small': {
            ...radioStyle('small'),
          },
          '&.size-medium': {
            ...radioStyle('medium'),
          },
        },
      },
      defaultProps: {
        className: 'size-small',
        icon: (
          <Box
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              border: '1px solid',
              borderColor: 'inherit',
            }}
            className="icon"
          />
        ),
        checkedIcon: (
          <Box
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              border: '1px solid',
              position: 'relative',
              borderColor: 'inherit',
            }}
            className="icon"
          >
            <Box
              sx={{
                top: 3,
                left: 3,
                width: 8,
                height: 8,
                borderRadius: '50%',
                position: 'absolute',
                backgroundColor: 'inherit',
              }}
              className="dot"
            />
          </Box>
        ),
      },
    },
  };
}

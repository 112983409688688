import { API } from '@constants';
import { bonusesApi } from '@modules/bonusManagement/service/bonusesApi';
import {
  type IBonus,
  type IBonusRatesPayload,
  type IBonusTemplatePayload,
  type IChangeStatusPayload,
  type ICreateBonusTemplate,
  type IEditBonusPayload,
  type IValidationWizardStep,
} from '@modules/bonusManagement/types';
import { type IBonusRate } from '@modules/common/brandsRate/types';
import { type ICommonError } from '@modules/types';
import { type Option } from '@shared/form/types';
import { catchCommonError } from '@utils/catchCommonError';
import replaceURLParams from '@utils/replaceURLParams';
import { responseToSelectOptions } from '@utils/responseFormatters';

export const bonusDetailsApi = bonusesApi.injectEndpoints({
  endpoints: (builder) => ({
    getBonus: builder.query<IBonus, string>({
      providesTags: ['bonus'],
      query: (id) => ({
        url: replaceURLParams(API.BONUS_MANAGEMENT.BONUS.ROOT, { id }),
      }),
    }),
    createBonusTemplate: builder.mutation<ICommonError, ICreateBonusTemplate>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.BONUS_MANAGEMENT.BONUS.CREATE_BONUS_TEMPLATE,
      }),
    }),
    editBonus: builder.mutation<IBonus, IEditBonusPayload>({
      query: (payload) => ({
        body: payload,
        method: 'PUT',
        url: replaceURLParams(API.BONUS_MANAGEMENT.BONUS.ROOT, {
          id: payload.id,
        }),
      }),
    }),
    validateWizardStep: builder.mutation<ICommonError, IValidationWizardStep>({
      query: ({ step, data }) => ({
        body: data,
        method: 'POST',
        url: replaceURLParams(API.BONUS_MANAGEMENT.BONUS.VALIDATE_WIZARD_STEP, {
          step,
        }),
      }),
    }),
    changeStatus: builder.mutation<unknown, IChangeStatusPayload>({
      invalidatesTags: ['bonus-list'],
      query: ({ id, enabled }) => ({
        method: 'POST',
        body: { enabled },
        url: replaceURLParams(API.BONUS_MANAGEMENT.BONUS.CHANGE_STATUS, {
          id,
        }),
      }),
    }),
    getBonusRates: builder.query<IBonusRate[], IBonusRatesPayload>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.MONETARY_VALUES.BONUS_RATES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
    }),
    validateEditWizardStep: builder.mutation<
      ICommonError,
      IValidationWizardStep
    >({
      query: ({ step, data }) => ({
        body: data,
        method: 'POST',
        url: replaceURLParams(
          API.BONUS_MANAGEMENT.BONUS.VALIDATE_EDIT_WIZARD_STEP,
          {
            id: String(data.id),
            step,
          }
        ),
      }),
    }),
    getBonusTemplates: builder.query<Option[], IBonusTemplatePayload>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.BONUS_MANAGEMENT.BONUS_TEMPLATES,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled.catch(({ error }) => {
          catchCommonError(error);
        });
      },
      transformResponse: (response: IBonus[]) =>
        responseToSelectOptions<IBonus[]>(response, {
          key: 'name',
          withId: true,
        }),
    }),
  }),
});

export const {
  useGetBonusQuery,
  useEditBonusMutation,
  useChangeStatusMutation,
  useLazyGetBonusRatesQuery,
  useLazyGetBonusTemplatesQuery,
  useValidateWizardStepMutation,
  useCreateBonusTemplateMutation,
  useValidateEditWizardStepMutation,
} = bonusDetailsApi;
